
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { mapActions, mapGetters } from 'vuex'
  import { Resource } from '@/entities/public'
  import { capitalize } from '@/utils/general'
  import { formFilter, insertInput, updateInput } from '@/graphql/generated-types'
  import { Link } from '@/store/app/state'
  import { Query } from '@/entities/public/Resource/interfaces'
  import { RULES } from '@/components/forms'

@Component({
  computed: {
    ...mapGetters('resources', ['getForm', 'links']),
  },
  methods: {
    ...mapActions('resources/form', ['fetchData', 'pushData', 'closeLead']),
    ...mapActions('resources', ['refresh']),
  },
})
  export default class ModelDialog extends Vue {
  $refs!: {
    formRemove: HTMLFormElement;
  };

  @Prop({ type: Number }) id: number
  @Prop({ type: String, required: true }) model!: string
  fetchData!: (payload: { query: Query, filter?: formFilter }) => Promise<any>
  getForm!: (model: string, slug?: string) => Resource
  _resource!: Resource;
  open = true
  closeLead!: (payload: { idLead: number, idClosingReason: number }) => Promise<void>
  refresh!: (payload: { force?: boolean }) => Promise<any>
  pushData!: (payload: { model: string, fields?: insertInput | updateInput }) => Promise<any>
  links!: Array<Link>;
  closingReason = []
  valid = false
  reasonType = -1
  rule = RULES.isRequired
  loading = false

  async mounted () {
    if (this.$refs.formRemove) {
      this.$refs.formRemove.reset()
    }

    this.closingReason = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason', order: { type: { description: 'asc' } } },
      filter: {
        _and: [
          {
            status: {
              _and: [
                { status: { name: { _eq: 'lost' } } },
                { process: { table_name: { _eq: 'lead' } } },
              ],
            },
          },
          { type: { name: { _neq: 'withdrawn_consignment' } } },
        ],
      },
    })
  }

  get closingReasonType () {
    if (!this.closingReason.length) return []

    return this.closingReason.map(reason => {
      return {
        id: reason.id,
        description: reason?.description || reason.type.description,
      }
    })
  }

  get resource () {
    const { _resource } = this
    if (_resource) return _resource

    const { model } = this

    return this._resource = this.getForm(capitalize(model))
  }

  get title () {
    const { resource: { name } } = this
    const title = name.toLowerCase()

    const isPlural = title.endsWith('s')
    return isPlural ? title.slice(0, -1) : title
  }

  get buildTitle () {
    const { title } = this
    return `¿Esta seguro de que quieres cerrar este ${title}?`
  }

  async submit () {
    if (!this.$refs.formRemove.validate()) return
    const { id, reasonType } = this
    this.loading = true
    await this.closeLead({ idLead: id, idClosingReason: reasonType })

    await this.closeForm()
  }

  close () {
    this.open = false
    this.closeForm()
  }

  async closeForm () {
    const { links } = this
    const link = links.find(({ route }) => this.$route.fullPath.includes(route.path))

    const { route: { path, name } } = link

    await this.refresh({ force: true })
    this.$router.push(path || { name }).catch(() => {
    })
  }
  }
